import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Accordion } from '@components/Accordion';
import PageLayout from '@components/layout/PageLayout';
import PageHero from '@components/layout/PageHero';
import * as styles from '@css/modules/privacy.module.scss';
import { useMPEvent } from '@util/mixpanel';

export default function Privacy({ data }) {
  const {
    node_locale: nodeLocale,
    pageTitle,
    header,
    subTitle: { subTitle },
    questions,
  } = data.contentfulPrivacyPage;
  const { trackPageLoad } = useMPEvent();

  useEffect(() => {
    trackPageLoad({
      language: nodeLocale.toLowerCase(),
      url: `/${nodeLocale}/privacy`,
      url_name: 'privacy',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageLayout node_locale={nodeLocale} pageTitle={pageTitle}>
      <PageHero data={{ header }} />

      <div className={styles.background}>
        <Container>
          <Row className="mb-5 justify-content-center">
            <Col md={10}>
              <p className="mb-5">{subTitle}</p>

              {questions.map((question) => (
                <Accordion
                  key={question.id}
                  className="mb-3"
                  header={question.header}
                  body={renderRichText(question.copy)}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query($node_locale: String!) {
    contentfulPrivacyPage(node_locale: { eq: $node_locale }) {
      node_locale
      pageTitle
      header
      subTitle {
        subTitle
      }
      questions {
        id
        header
        copy {
          raw
        }
      }
    }
  }
`;
