// extracted by mini-css-extract-plugin
export const groupedAccordion = "accordion-module--groupedAccordion--29lfV";
export const accordionContainer = "accordion-module--accordionContainer--WcBhZ";
export const accordionHeader = "accordion-module--accordionHeader--1zqd6";
export const accordionBody = "accordion-module--accordionBody--3XUqp";
export const accordionHeaderText = "accordion-module--accordionHeaderText--UFT_M";
export const accordionIcon = "accordion-module--accordionIcon--3eu7p";
export const accordionDetails = "accordion-module--accordion-details--2zJQr";
export const detailsCardTogglerIcon = "accordion-module--details-card-toggler-icon--2N2VE";
export const detailsCardToggler = "accordion-module--details-card-toggler--iUbv9";
export const accordionToggle = "accordion-module--accordionToggle--3gXse";